import getEnv from '@/utils/env';
import { SETTINGS } from '../../wettbewerbsvergleich/src/utils/constants';

import profileConfigDe from './demoUser/profileDataDe.json';
import profileConfigEn from './demoUser/profileDataEn.json';

export const CHART_GENERATOR_DETAIL_FORM_HINTS = {
  split: 'chartGenerator.hints.split',
  differentialValue: 'chartGenerator.hints.differentialValue',
  annotation: 'chartGenerator.hints.annotation',
  showLogo: 'chartGenerator.hints.showLogo',
};

export const CURRENT_YEAR = new Date().getFullYear();

// see de.js/en.js
export const INTEREST_CREDIT_TAGESGELD = {
  0: 'common.generalTable.interestCreditCallMoney.monthEnd',
  1: 'common.generalTable.interestCreditCallMoney.quarterEnd',
  2: 'common.generalTable.interestCreditCallMoney.yearEnd',
  3: 'common.generalTable.interestCreditCallMoney.daily',
  4: 'common.generalTable.interestCreditCallMoney.halfYearEnd',
  5: 'common.generalTable.interestCreditCallMoney.startOfMonth',
};
export const INTEREST_CREDIT_FESTGELD = {
  0: 'common.generalTable.interestCreditFixedDeposit.monthly',
  1: 'common.generalTable.interestCreditFixedDeposit.quarterly',
  2: 'common.generalTable.interestCreditFixedDeposit.semiannual',
  3: 'common.generalTable.interestCreditFixedDeposit.yearly',
  4: 'common.generalTable.interestCreditFixedDeposit.termEnd',
};
export const INTEREST_CALCULATION = {
  0: 'common.generalTable.interestCalculation.germanMethod',
  1: 'common.generalTable.interestCalculation.euroInterestMethod',
  2: 'common.generalTable.interestCalculation.englishMethod',
  3: 'common.generalTable.interestCalculation.effectiveInterestMethod',
};
export const COLOR = '#1194db';
export const BASE_COLOR = '#83929b';
export const BASE_BLACK_COLOR = '#181818';
export const SELECTED_COLOR = '#00ab97';

export const TAGS_BAUGELD = [
  {
    name: 'Top-Wettbewerber',
    name_en: 'Top Competitors',
  },
  {
    name: 'Regionale Anbieter',
    name_en: 'Top Competitors',
  },
  {
    name: 'Vermittler',
    name_en: 'Brokers',
  },
  {
    name: 'MaRisk',
    name_en: 'Minimum Requirements for Risk Management',
  },
  {
    name: 'Forward-Darlehen',
    name_en: 'Forward Loan',
  },
  {
    name: 'Förderdarlehen (KfW)',
    name_en: 'Promotional Loan (KfW)',
  },
];
export const TAGS_TAGES_FEST = [
  {
    name: 'Deutsche Anbieter',
    name_en: 'German Providers',
  },
  {
    name: 'Vermittler',
    name_en: 'Brokers',
  },
  {
    name: 'Erweiterte Einlagensicherung',
    name_en: 'Extended Deposit Guarantee',
  },
  {
    name: 'flexible Angebote',
    name_en: 'flexible offers',
  },
  {
    name: 'Top-Wettbewerber',
    name_en: 'Top Competitors',
  },
  {
    name: 'Neukunden',
    name_en: 'New Customers',
  },
  {
    name: 'Freshmoney',
    name_en: 'Fresh money',
  },
  {
    name: 'Regionale Anbieter',
    name_en: 'Top Competitors',
  },
];

export const KEYS_TAGS_BAUGELD = TAGS_BAUGELD.map((tag) => tag.name);
export const PUBLIC_PAGES = [
  '/rate-chart',
  '/login',
  '/forgot-password',
  '/reset-password',
];
export const NAMES_PUBLIC_PAGES = [
  'rate-chart',
  'login',
  'forgot-password',
  'reset-password',
];
export const NAVIGATION_CONFIG = [
  {
    isPanel: false,
    permission: 'rest',
    to: '/dashboard',
    icon: 'mdi-view-dashboard',
    title: 'sidebar.menu.dashboard',
    items: [],
  },
  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-clock-time-three-outline',
    title: 'sidebar.menu.liveData',
    label: 'Live-Daten',
    permission: 'rest',
    tooltip: true,
    nudge: {
      true: -90,
      false: 40,
    },
    tooltipText: 'sidebar.tooltips.liveData',
    items: [
      {
        to: '/baugeld',
        title: 'sidebar.menu.buildingMoney',
        permission: 'mortgage.liveData',
      },
      {
        to: '/tagesgeld',
        title: 'sidebar.menu.callMoney',
        permission: 'callMoney.liveData',
      },
      {
        to: '/festgeld',
        title: 'sidebar.menu.fixedDeposit',
        permission: 'timeDeposits.liveData',
      },
    ],
  },
  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-chart-bar',
    title: 'sidebar.menu.contest',
    label: 'Historische Daten',
    permission: 'rest',
    tooltip: true,
    nudge: {
      true: -100,
      false: 40,
    },
    tooltipText: 'sidebar.tooltips.contest',
    items: [
      {
        to: '/wettbewerb-baugeld',
        title: 'sidebar.menu.buildingMoney',
        permission: 'mortgage.competition',
      },
      {
        to: '/wettbewerb-tagesgeld',
        title: 'sidebar.menu.callMoney',
        permission: 'callMoney.competition',
      },
      {
        to: '/wettbewerb-festgeld',
        title: 'sidebar.menu.fixedDeposit',
        permission: 'timeDeposits.competition',
      },
    ],
  },
  {
    isPanel: false,
    permission: [
      'callMoney.interestCharts',
      'mortgage.interestCharts',
      'timeDeposits.interestCharts',
    ],
    to: '/chart-generator',
    icon: 'mdi-chart-line-variant',
    title: 'sidebar.menu.interestCharts',
    nudge: {
      true: 10,
      false: 15,
    },
    tooltip: true,
    tooltipText: 'sidebar.tooltips.chartGenerator',
    items: [],
  },
  {
    isPanel: false,
    permission: [
      'callMoney.profile',
      'mortgage.profile',
      'timeDeposits.profile',
    ],
    to: '/banking-profile',
    icon: 'mdi-account-outline',
    title: 'sidebar.menu.providerProfiles',
    tooltip: true,
    nudge: {
      true: 10,
      false: 15,
    },
    tooltipText: 'sidebar.tooltips.bankingProfile',
    items: [],
  },

  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-format-list-bulleted',
    title: 'sidebar.menu.nibc',
    permission: 'product.nibc',
    tooltip: false,
    items: [
      {
        to: '/spezialabonnenten-tagesgeld',
        title: 'sidebar.menu.callMoney',
        permission: 'product.nibc',
      },
      {
        to: '/spezialabonnenten-festgeld',
        title: 'sidebar.menu.fixedDeposit',
        permission: 'product.nibc',
      },
    ],
    label: 'NIBC',
  },
  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-format-list-bulleted',
    title: 'sidebar.menu.kfw',
    permission: 'product.kfw',
    tooltip: false,
    label: 'KfW',
    items: [
      {
        to: '/kfw-baugeld',
        title: 'sidebar.menu.buildingMoney',
        permission: 'product.kfw',
      },
    ],
  },
];

export const HEADLINES = {
  latest: 'common.dataIterator.groupHeadline.latest',
  autoUpdate: 'common.dataIterator.groupHeadline.autoUpdate',
  other: 'common.dataIterator.groupHeadline.other',
};

export const FIELDS_TRANSLATE = {
  leverageRatio: 'leverageRatio',
  eigenkapital: 'equity',
  bilanzsumme: 'totalAssets',
  marktkapitalisierung: 'marketCapitalization',
  gesamtkapitalquote: 'totalCapitalRatio',
  kernkapitalquote: 'coreCapitalRatio',
  eigenkapitalrendite: 'returnOnEquity',
  mitarbeiter: 'employees',
  filialen: 'branchOffices',
  standortlaender: 'locations',
  schwerpunkte: 'keyAreas',
  bankratings: 'bankRatings',
  landkuerzel: 'headOffice',
  gewinn: 'profit',
};
export const SERIES_BACKGROUND_COLOR = {
  0: '#ff5a5f',
  1: '#7b0051',
  2: '#007A87',
  3: '#00d1c1',
  4: '#8ce071',
  5: '#ffb400',
  6: '#b4a76c',
  7: '#ff8083',
  8: '#cc0086',
  9: '#00a1b3',
  10: '#00ffeb',
  11: '#bbedab',
  12: '#ffd266',
  13: '#cbc29a',
  14: '#ff3339',
  15: '#ff1ab1',
  16: '#005c66',
  17: '#00b3a5',
  18: '#55d12e',
  19: '#b37e00',
  20: '#988b4e',
};
export const DEFAULT_SELECTED_HEADERS_BANKING_PROFILE = [
  {
    value: 'gesamtkapitalquote',
    text: 'totalCapitalRatio',
    sortable: true,
  },
  { value: 'eigenkapitalrendite', text: 'returnOnEquity', sortable: true },
  { value: 'schwerpunkte', text: 'keyAreas', sortable: false },
  { value: 'bankratings', text: 'bankRatings', sortable: false },
];
export const DEFAULT_HEADERS_BANKING_PROFILE = [
  { value: 'ordinalNumber', text: '', sortable: false },
  { value: 'anbietername', text: 'provider', sortable: true },
  { value: 'standortlaender', text: 'locations', sortable: false },
  { value: 'landkuerzel', text: 'headOffice', sortable: true },
];
export const ADDITIONAL_HEADERS_BANKING_PROFILE = [
  { value: 'eigenkapital', text: 'equity', sortable: true },
  { value: 'bilanzsumme', text: 'totalAssets', sortable: true },
  { value: 'gewinn', text: 'profit', sortable: true },
  {
    value: 'provisionseinnahmen',
    text: 'commissionIncome',
    sortable: false,
    children: [
      {
        value: 'provisionsaufwendungen',
        text: 'commissionExpenses',
        sortable: true,
      },
      {
        value: 'provisionsertrag',
        text: 'netCommissionIncome',
        sortable: true,
      },
      {
        value: 'provisionsueberschuss',
        text: 'commissionSurplus',
        sortable: true,
      },
    ],
  },
  {
    value: 'kreditvolumen',
    text: 'creditVolume',
    sortable: false,
    children: [
      { value: 'grundpfandrecht', text: 'lienOnRealProperty', sortable: true },
      {
        value: 'forderungenKreditinstitute',
        text: 'receivablesFromBanks',
        sortable: true,
      },
      {
        value: 'forderungenKunden',
        text: 'receivablesCustomers',
        sortable: true,
      },
    ],
  },
  {
    value: 'anlagevolumen',
    text: 'investmentVolume',
    sortable: false,
    children: [
      { value: 'spareinlagen', text: 'savingsDeposits', sortable: true },
      {
        value: 'andereVerbindlichkeiten',
        text: 'otherliabilities',
        sortable: true,
      },
      {
        value: 'summeVerbindlichkeiten',
        text: 'totalPayables',
        sortable: true,
      },
    ],
  },
  { value: 'einlagensicherung', text: 'depositInsurance', sortable: true },
  {
    value: 'marktkapitalisierung',
    text: 'marketCapitalization',
    sortable: true,
  },
  { value: 'eigenkapitalrendite', text: 'returnOnEquity', sortable: true },
  { value: 'kernkapitalquote', text: 'coreCapitalRatio', sortable: true },
  { value: 'gesamtkapitalquote', text: 'totalCapitalRatio', sortable: true },
  { value: 'leverageRatio', text: 'leverageRatio', sortable: true },
  { value: 'mitarbeiter', text: 'employees', sortable: true },
  { value: 'filialen', text: 'branchOffices', sortable: true },
  { value: 'schwerpunkte', text: 'keyAreas', sortable: false },
  { value: 'gesellschafter', text: 'shareholder', sortable: true },
  { value: 'tochtergesellschaften', text: 'subsidiaries', sortable: true },
  { value: 'bankratings', text: 'bankRatings', sortable: false },
  { value: 'gruendungsort', text: 'foundationLocation', sortable: true },
  { value: 'gruendungsjahr', text: 'foundationYear', sortable: true },
];

export const SUFFIXES = {
  provisionsueberschuss: 'Mio €',
  gewinn: 'Mio €',
  provisionsertrag: 'Mio €',
  provisionsaufwendungen: 'Mio €',
  summeVerbindlichkeiten: 'Mio €',
  andereVerbindlichkeiten: 'Mio €',
  spareinlagen: 'Mio €',
  forderungenKunden: 'Mio €',
  forderungenKreditinstitute: 'Mio €',
  grundpfandrecht: 'Mio €',
  bilanzsumme: 'Mio €',
  eigenkapital: 'Mio €',
  marktkapitalisierung: 'Mio €',
  leverageRatio: '%',
  gesamtkapitalquote: '%',
  kernkapitalquote: '%',
  gesellschafter: '%',
  gesamtkapitalquoteLand: '%',
  gesamtkapitalquoteDurchschnitt: '%',
  eigenkapitalrenditeDurchschnitt: '%',
  eigenkapitalrendite: '%',
};
export const SUFFIXES_DEPOSIT = {
  0: '',
  1: 'Tsd',
  2: 'Mio',
  3: 'Mrd',
  4: 'unbegrenzt',
};
export const LIVE_DATA_CONFIG = [
  {
    text: 'sidebar.menu.buildingMoney',
    value: { link: '/baugeld', permission: 'mortgage.liveData' },
  },
  {
    text: 'sidebar.menu.fixedDeposit',
    value: { link: '/festgeld', permission: 'callMoney.liveData' },
  },
  {
    text: 'sidebar.menu.callMoney',
    value: { link: '/tagesgeld', permission: 'timeDeposits.liveData' },
  },
];
export const WETTBEWERB_CONFIG = [
  {
    text: 'sidebar.menu.buildingMoney',
    value: {
      link: '/wettbewerb-baugeld',
      permission: 'mortgage.competition',
    },
  },
  {
    text: 'sidebar.menu.fixedDeposit',
    value: {
      link: '/wettbewerb-festgeld',
      permission: 'timeDeposits.competition',
    },
  },
  {
    text: 'sidebar.menu.callMoney',
    value: {
      link: '/wettbewerb-tagesgeld',
      permission: 'callMoney.competition',
    },
  },
];
export const TEXT_ACTION_BUTTONS = {
  confirm: 'confirm',
  reject: 'reject',
};
export const SNACK_BAR_DATA = {
  text: 'Bei der API Anfrage trat ein Fehler auf.',
  color: '',
  dark: true,
  show: true,
  timeout: 3000,
};
export const LANGUAGES = [
  { flag: 'eu', value: 'en', title: 'English' },
  { flag: 'de', value: 'de', title: 'German' },
];
export const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 5,
      right: 40,
      top: 20,
      bottom: 20,
    },
  },
  scales: {
    x: {
      scaleFontSize: 24,
      id: 'x-axis-0',
      grid: {
        drawTicks: false,
        borderDash: [2, 4],
        color: SETTINGS.gridLinesColor,
      },
      ticks: {
        autoSkip: true,
        beginAtZero: true,
        maxTicksLimit: window.innerWidth / 150,
        padding: 10,
        maxRotation: 0,
        minRotation: 0,
      },
    },
    y: {
      id: 'y-axis-0',
      grid: {
        tickMarkLength: 10,
        borderDash: [3, 3],
        color: SETTINGS.gridLinesColor,
      },
      scaleLabel: {
        display: true,
      },
      ticks: {
        font: {
          size: 14,
        },
        padding: 10,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      onClick: function () {
        return false;
      },
      maxWidth: 10,
      fullSize: true,
      labels: {
        fullSize: true,
        font: {
          weight: 'bold',
          size: 14,
          fontColor: '#181818',
        },
      },
    },
  },
};
export const OFFERS = {
  8: {
    1: 'common.dataIterator.simpleTable.privatperson',
    2: 'common.dataIterator.simpleTable.businessClient',
  },
  1: {
    0: 'common.dataIterator.simpleTable.allOffers',
    1: 'common.dataIterator.simpleTable.onlyNewCustomersOffers',
    2: 'common.dataIterator.simpleTable.existingCustomersOffers',
  },
};
export const PROFILE_DATA_FIELDS = {
  kreditvolumen:
    'kreditvolumen { \n grundpfandrecht \n forderungenKreditinstitute \n forderungenKunden \n }',
  anlagevolumen:
    'anlagevolumen { \n spareinlagen \n andereVerbindlichkeiten \n summeVerbindlichkeiten \n }',
  provisionseinnahmen:
    'provisionseinnahmen { \n provisionsaufwendungen \n provisionsertrag \n provisionsueberschuss \n }',
  gewinn: 'gewinn { \n jahr \n gewinn \n }',
  anbietername: 'anbietername',
  marke: 'marke',
  landname: 'landname',
  landkuerzel: 'landkuerzel',
  gruendungsort: 'gruendungsort',
  gruendungsjahr: 'gruendungsjahr',
  datumLetzteAktualisierung: 'datumLetzteAktualisierung',
  standorte: 'standorte',
  standortlaender: 'standortlaender',
  boersennotiert: 'boersennotiert',
  gesellschafter: 'gesellschafter { \n gesellschaft \n anteil \n }',
  tochtergesellschaften: 'tochtergesellschaften',
  mitarbeiter: 'mitarbeiter { \n jahr \n anzahl \n }',
  filialen: 'filialen { \n jahr \n anzahl \n }',
  kerngeschaeft: 'kerngeschaeft { \n produktname \n produktart \n }',
  schwerpunkte: 'schwerpunkte',
  hashtags: 'hashtags',
  hypothekenvolumen:
    'hypothekenvolumen { \n bezeichnung \n hypothekenvolumen \n }',
  kernkapitalquote: 'kernkapitalquote { \n jahr \n kernkapitalquote \n }',
  marktkapitalisierung:
    'marktkapitalisierung { \n jahr \n marktkapitalisierung \n }',
  eigenkapital: 'eigenkapital { \n jahr \n eigenkapital \n }',
  eigenkapitalrendite:
    'eigenkapitalrendite { \n jahr \n eigenkapitalrendite \n }',
  eigenkapitalrenditeDurchschnitt: 'eigenkapitalrenditeDurchschnitt',
  gesamtkapitalquote: 'gesamtkapitalquote { \n jahr \n gesamtkapitalquote \n }',
  gesamtkapitalquoteDurchschnitt: 'gesamtkapitalquoteDurchschnitt',
  gesamtkapitalquoteLand: 'gesamtkapitalquoteLand',
  bilanzsumme: 'bilanzsumme { \n jahr \n bilanzsumme \n }',
  leverageRatio: 'leverageRatio { \n jahr \n leverageRatio \n }',
  ratingMuttergesellschaft: 'ratingMuttergesellschaft',
  bankratings: 'bankratings { \n ratingagentur \n rating \n }',
  landratings: 'landratings { \n rating \n ratingagentur \n }',
  einlagensicherung:
    'einlagensicherung { \n bezeichnung \n infotext \n landname \n betrag \n betragZahlenname \n waehrung \n}',
};

export const PROFILE_TABLE_DATA_DEMO_DE = profileConfigDe.data;
export const PROFILE_TABLE_DATA_DEMO_EN = profileConfigEn.data;
export const EXTENSIONS = {
  'application/pdf': '.pdf',
  'application/ms-excel': '.xls',
  'text/csv': '.csv',
};
export const DEFAULT_STR_OPTIONS = {
  standortlaender: [
    'Deutschland',
    'Algerien',
    'Angola',
    'Argentinien',
    'Australien',
    'Ägypten',
    'Äthiopien',
    'Bahrain',
    'Bangladesch',
    'Belarus',
    'Belgien',
    'Bosnien & Herzegowina',
    'Brasilien',
    'Bulgarien',
    'Burkina Faso',
    'Chile',
    'China',
    'Dänemark',
    'Deutschland (Berlin)',
    'Dschibuti',
    'Elfenbeinküste',
    'England',
    'Estland',
    'Finnland',
    'Frankreich',
    'Georgien',
    'Ghana',
    'Griechenland',
    'Großbritannien',
    'Guinea',
    'Idien',
    'Indien',
    'Indonesien',
    'Irak',
    'Irland',
    'Israel',
    'Italien',
    'Japan',
    'Jemen',
    'Jordanien',
    'Kanada',
    'Kasachstan',
    'Kenia',
    'Kroatien',
    'Kuwait',
    'Lettland',
    'Libanon',
    'Libyen',
    'Litauen',
    'Luxemburg',
    'Malaysia',
    'Mali',
    'Malta',
    'Marokko',
    'Mauretanien',
    'Mexiko',
    'Montenegro',
    'Mosambik',
    'Namibia',
    'Neuseeland',
    'Niederlande',
    'Niederlande (Amsterdam)',
    'Nigeria',
    'Norwegen',
    'Oman',
    'Österreich',
    'Pakistan',
    'Panama',
    'Philippinen',
    'Polen',
    'Portugal',
    'Rumänien',
    'Russland',
    'Saudi Arabien',
    'Schweden',
    'Schweiz',
    'Senegal',
    'Serbien',
    'Singapur',
    'Slowakei',
    'Slowenien',
    'Spanien',
    'Sri Lanka',
    'Südafrika',
    'Sudan',
    'Südkorea',
    'Taiwan',
    'Thailand',
    'Tschad',
    'Tschechien',
    'Tschechische Republik',
    'Tunesien',
    'Türkei',
    'Turkmenistan',
    'Uganda',
    'Ukraine',
    'Ungarn',
    'Usbekistan',
    'USA',
    'Venezuela',
    'Vereinigte Arabische Emirate',
    'Vereinigtes Königreich',
    'Vietnam',
    'Zypern',
  ],
  schwerpunkte: [
    'Absatzfinanzierung',
    'Absicherung biometrischer Risiken',
    'Agrarindustrie',
    'Akkreditive',
    'Altersvorsorge',
    'Altersvorsorge und Versicherungen',
    'Ankauf von Leasing-, Mietkauf- und Factoringforderungen der abcfinance GmbH',
    'Ankauf von Leasingforderungen',
    'Anlage',
    'Anlagemanagement',
    'Anlageprodukte',
    'Anlagevermittlung',
    'Anleihen',
    'Anschlussfinanzierung',
    'Asset Management',
    'Ausleihen',
    'Ausstellung von Garantien',
    'Auszahlplan',
    'Autokredite',
    'Autoleasing',
    'Autoleasing/Operatingleasing',
    'Bank- und Finanzdienstleistungen',
    'Bank- und Kreditkarten',
    'Bank- und Zahlungsdienstleistungen',
    'Bankdienstleistungen',
    'Bankgarantien',
    'Banking für Geschäftskunden und Unternehmen',
    'Bankkarten',
    'Bankleistungen für Unternehmen und institutionelle Einrichtungen',
    'Bargeld- und Abwicklungsgeschäfte',
    'Bargeldloser Zahlungsverkehr',
    'Bau und sonstige Entwicklung von Immobilien',
    'Baudarlehen',
    'Baufinanzierung',
    'Baufinanzierung und Bausparen',
    'Baufinanzierung und Immobilien',
    'Baufinanzierung, Bausparen',
    'Baugewerbe',
    'Bauspar- und Baufinanzierungsgeschäft',
    'Bausparen',
    'Bauspargeschäft',
    'Bausparvertrag',
    'Beratung',
    'Beratung Finanzbedarf',
    'Beratung Immobilienkauf',
    'Beratung: Kredite, Festgeld',
    'Betriebsmittelfinanzierung',
    'Betriebsmittelkredite (KMU)',
    'Bezahllösungen',
    'Beziehungsbank',
    'Blankodarlehen',
    'Branchenunabhängige Unternehmensfinanzierung',
    'Brokerage',
    'Brokerage-Dienstleistungen',
    'Cash flow management',
    'Corporate Banking',
    'Corporate Banking und Finance',
    'Darlehen',
    'Darlehen und Leasing',
    'Darlehen/Kredite',
    'Datendienste',
    'Debitkarten',
    'Devisendienstleistungen',
    'Devisengeschäft',
    'Digital Banking',
    'Digital Banking/Payments',
    'Digitale Bankdienstleistungen',
    'Direktbanking',
    'Direktkredite für Endverbraucher',
    'Direktverkauf',
    'E-commerce',
    'E-Commerce',
    'E-Credit',
    'E-finance',
    'E-Finance',
    'Ecommerce-Lösungen mit digitalen Zahlungslösungen für Online-Händler',
    'Einlagen',
    'Einlagen und Girokonten',
    'Einlagengeschäft',
    'Einlagenkonten',
    'Einlagensicherung',
    'Einzel- und Großhandel',
    'Einzelhandel und Unternehmen',
    'Ersparnisse',
    'Erwerb von Grundstücken',
    'Export- und Import-Finanzierung',
    'Exportfinanzierung',
    'Factoring',
    'Factoring und Leasing',
    'Factoring und Unternehmenskredite',
    'Factoring- und Kreditmanagement',
    'Fahrzeugfinanzierung',
    'Festgeld',
    'Filialgeschäft',
    'Financial Services',
    'Finanz- und Versicherungsdienstleistungen',
    'Finanzdienstleistungen',
    'Finanzdienstleistungen Privatkunden',
    'Finanzierung',
    'Finanzierung des internationalen Handels',
    'Finanzierung v. Unternehmenskunden',
    'Finanzierung von Baugrundstücken',
    'Finanzierung von Gewerbeimmobilien',
    'Finanzierung von privatem Wohneigentum',
    'Finanzierung von Projekten, die im Rahmen der operationellen Programme der EU durchgeführt werden',
    'Finanzierung von Umweltprojekten',
    'Finanzierung von Wohnimmobilien',
    'Finanzierungen',
    'Finanzierungen der öffentlichen Hand',
    'Finanzierungsleistungen',
    'Finanzierungslösungen für Handelstransaktionen',
    'Finanzierungsprogramm KMU',
    'Firmen- und Privateinlagen',
    'Firmen- und Privatkundengeschäft',
    'Firmenkredite',
    'Firmenkunden und Investment Banking',
    'Firmenkundengeschäft',
    'Firmenkundengeschäft und Investmentbanking',
    'Firmenkundenkredite, gewerbliche Immobilienfinanzierung',
    'Fondsberatung',
    'Fondsvermittlung',
    'Forward-Darlehen',
    'Fracht',
    'Fremd- und Eigenkapialfinanzierung',
    'Gebrauchtmaschinenfinanzierung',
    'Geförderte Finanzierungen',
    'Geldanlage',
    'Geldanlage, Versicherungen',
    'Geldanlagen',
    'Genehmigungsraten',
    'Geräteleasing',
    'Geschäfte mit Wertpapieren und Devisen',
    'Geschäftskonten',
    'Geschäftskunden',
    'Geschäftskundenbanking',
    'Geschäftskundengeschäft',
    'gewerbliches Immobilienfinanzierungsgeschäft',
    'Giro & Karten',
    'Giro- und Spareinlagengeschäft, Bauspar- und Baufinanzierungsprodukte, Privatkredite',
    'Girokonten',
    'Girokonten und Kreditkarten',
    'Girokonto',
    'Girokonto & Karten',
    'Groß- und Einzelhandel',
    'Handel',
    'Handel und Rohstofffinanzierung',
    'Handelsfinanzierung',
    'Handelswarenfinanzierung',
    'Hauptversicherungen',
    'Haushaltsfinanzen',
    'Haushypothek',
    'Händlereinkaufsfinanzierung',
    'Hypotheken',
    'Hypotheken und Darlehen',
    'Hypothekendarlehen',
    'Immobilendarlehen',
    'Immobilien',
    'Immobiliendarlehen',
    'Immobilienfinanzierung',
    'Immobilienkredite',
    'Immobilienverkaufsberatung',
    'Immobilienvermietung',
    'Import und Export',
    'Individuelle Produkte und Vermögensschutz',
    'Innovative Banktechnologien',
    'Institutional Banking',
    'internationale Einlagen',
    'Internationale Handelsfinanzierung',
    'Internetkredite',
    'Investitionen',
    'Investitionsdienstleistungen',
    'Investitionskreditgeschäft',
    'Investitionsprodukte',
    'Investment',
    'Investment & Brokerage Dienstleistungen',
    'Investmentbank',
    'Investmentbanking',
    'Investmentfonds',
    'IT-Lösungen',
    'Kapitalanlagen in Form von Festgeldverträgen und Prämien-/Entnahmedepots',
    'Kapitalisierungsgeschäfte',
    'Kapitalmärkte',
    'Karten',
    'Kartenausstellung',
    'Kartengeschäft',
    'Kartenprodukte',
    'Kleine und mittlere Unternehmen',
    'Kleinkredite',
    'KMU-Banking',
    'Kommerzielle Bankgeschäfte im Privatkundengeschäft',
    'Kommerzielle Kredite',
    'Konsumentenkredite',
    'Konten',
    'Konten für Privatkunden und KMUs',
    'Konten und Karten',
    'Kredit- und Debitkarten',
    'Kredit- und Einlagengeschäft',
    'Kredite',
    'Kredite & Finanzierung',
    'Kredite an Unternehmen',
    'Kredite für Freiberufler',
    'Kredite für Kleine und mittlere Unternehmen',
    'Kredite für kleine und mittlere Unternehmen und Privatpersonen',
    'Kredite für KMU',
    'Kredite für private Marktteilnehmer',
    'Kredite und Baufinanzierung',
    'Kredite und Darlehen',
    'Kredite und Finanzierung',
    'Kredite und Finanzierungen',
    'Kreditgeschäft',
    'Kreditkarten',
    'Kreditlösungen',
    'Kreditprodukte',
    'Kreditvergabe',
    'Kreditvergabe und Bankdienstleistungen',
    'Kundenkontoführung',
    'Kundenkredite',
    'Kurz- und mittelfristige Darlehen für Industrie- und Handelskunden',
    'Landwirtschaft, Forstwirtschaft und Fischerei',
    'langfristige Darlehen für Unternehmen in der EU',
    'Leasing',
    'Lebensversicherung',
    'Lebensversicherungen',
    'Leistungen für Unternehmenskunden und vermögende Privatpersonen',
    'Logistik',
    'Lösung für private Finanzplanung',
    'Management der Finanzströme',
    'Mietkaufgeschäft',
    'Mikrokredite',
    'Mittelfristige Darlehen an KMU, garantiert durch die italienische Regierung',
    'Mittelstandsfinanzierung',
    'Modernisierungskredite',
    'Nachhaltigkeit',
    'Online- und Mobile-Banking',
    'Online-Banking',
    'Onlinebanking',
    'Öffentliche Kunden',
    'Partnerbanking',
    'Payment',
    'Payment-Lösungen',
    'Peer-to-Peer-Kredite',
    'Point-of-Sale-Finanzierung',
    'Point-of-Sales-Finanzierung',
    'Privat- und Unternehmenskredite',
    'Privatdarlehen',
    'Private Banking',
    'Private Kapitalanlagen',
    'Private und institutionelle Einlagen',
    'Privates Banking',
    'Privatkonten',
    'Privatkonten und Sparangebote',
    'Privatkredite',
    'Privatkunden',
    'Privatkundenbank',
    'Privatkundengeschäft',
    'Projektfinanzierungen für Unternehmen',
    'Provisionsgeschäft',
    'Ratenkredite',
    'Ratenkreditgeschäft',
    'Refinanzierung',
    'Retail Banking',
    'Schifffahrtsbranche',
    'Schuldenverschreibung',
    'SME-Kreditgeschäft',
    'SMEs Banking',
    'Social Shopping',
    'Spar-Management',
    'Sparbank',
    'Sparen und Anlegen',
    'Sparen und Geldanlage',
    'Sparkonten',
    'Sparkonto',
    'Sparprodukte',
    'Spezialdarlehen',
    'Tagesgeld',
    'Tägliches Bankgeschäft',
    'Termineinlagen',
    'Trade Finance',
    'Treasury',
    'Treasury-Transaktionen',
    'Umfangreiche Finanzdienstleistungen',
    'Umschuldung von Krediten',
    'Ungesicherte Kredite',
    'Unternehmensberatung',
    'Unternehmensdarlehen',
    'Unternehmensderivate',
    'Unternehmensfinanzierung',
    'Unternehmenskredite',
    'Unternehmenskredite für KMU',
    'Unternehmerbank',
    'Unternehmerkundengeschäft',
    'Verantwortungsvolles Banking',
    'Verarbeitendes Gewerbe',
    'Verbraucherdarlehen',
    'Verbraucherdarlehen mit Schwerpunkt auf ungesicherten Krediten',
    'Verbraucherfinanzierung',
    'Verbraucherkredite',
    'Vergabe von Krediten an Mitglieder',
    'Vermittlung von Immobilien',
    'Vermittlung von Versicherungs- und Bausparverträge',
    'Vermögensanlage',
    'Vermögensverwaltung',
    'Verrechnungskonten',
    'Versicherung',
    'Versicherung & Service',
    'Versicherungen',
    'Versicherungen und Altersvorsorge',
    'Versicherungsgeschäft',
    'Versicherungsleistungen',
    'Versicherungsschutz im In- und Ausland',
    'Versicherungsvermittlung',
    'Verwaltung von Geldern',
    'Verwaltung von Kreditportfolios',
    'Vielfältiges Finanzangebot für Institutionen',
    'Vielfältiges Finanzangebot für Privatkunden',
    'Vorsorge',
    'Vorsorge & Versicherungen',
    'Vorsorge und Versicherung',
    'Vorsorgen & Versichern',
    'Vorsorgen und Versichern',
    'Wertebasierter Dienstleister',
    'Wertpapier- und Depotgeschäft',
    'Wertpapierdepots',
    'Wertpapiergeschäft',
    'Wertpapierhandel',
    'Wholesale Banking',
    'Zahlungen',
    'Zahlungs- und Transaktionsdienstleistungen',
    'Zahlungslösungen',
    'Zahlungslösungen für E-Commerce und Einzelhandelsketten',
    'Zahlungssysteme',
    'Zahlungsverkehr',
    'Zentralregulierung mit Delkredereübernahme im Einzel- und Großhandel',
    'Zentralregulierung ohne Delkredereübernahme im Einzel- und Großhandel',
    'Zusatzversicherungen',
  ],
};
export const DACH_COUNTRIES = ['Deutschland', 'Österreich', 'Schweiz'];
export const EU_COUNTRIES = [
  'Belgien',
  'Deutschland',
  'Frankreich',
  'Italien',
  'Luxemburg',
  'Niederlande',
  'Dänemark',
  'Irland',
  'Griechenland',
  'Portugal',
  'Spanien',
  'Finnland',
  'Österreich',
  'Schweden',
  'Estland',
  'Lettland',
  'Litauen',
  'Malta',
  'Polen',
  'Slowakei',
  'Slowenien',
  'Tschechien',
  'Ungarn',
  'Zypern',
  'Bulgarien',
  'Rumänien',
  'Kroatien',
];
export const EUROPA_COUNTRIES = [
  'Albanien',
  'Andorra',
  'Belarus',
  'Belgien',
  'Bosnien und Herzegowina',
  'Bulgarien',
  'Dänemark',
  'Deutschland',
  'Estland',
  'Finnland',
  'Frankreich',
  'Griechenland',
  'Irland',
  'Island',
  'Italien',
  'Kasachstan',
  'Kosovo',
  'Kroatien',
  'Lettland',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Moldau',
  'Monaco',
  'Montenegro',
  'Niederlande',
  'Nordmazedonien',
  'Norwegen',
  'Österreich',
  'Polen',
  'Portugal',
  'Rumänien',
  'Russland',
  'San Marino',
  'Schweden',
  'Schweiz',
  'Serbien',
  'Slowakei',
  'Slowenien',
  'Spanien',
  'Tschechien',
  'Türkei',
  'Ukraine',
  'Ungarn',
  'Vatikanstadt',
  'Vereinigtes Königreich',
];
export const BENELUX_COUNTRIES = ['Belgien', 'Niederlande', 'Luxemburg'];
export const BRICS_COUNTRIES = [
  'Brasilien',
  'Russland',
  'Indien',
  'China',
  'Südafrika',
];
export const AFRICA_COUNTRIES = [
  'Ägypten',
  'Algerien',
  'Angola',
  'Äquatorialguinea',
  'Äthiopien',
  'Benin',
  'Botswana',
  'Burkina Faso',
  'Burundi',
  'Dschibuti',
  'Elfenbeinküste',
  'Eritrea',
  'Eswatini',
  'Gabun',
  'Gambia',
  'Ghana',
  'Guinea',
  'Guinea-Bissau',
  'Kamerun',
  'Kap Verde',
  'Kenia',
  'Komoren',
  'Kongo, Demokratische Republik',
  'Kongo, Republik',
  'Lesotho',
  'Liberia',
  'Libyen',
  'Madagaskar',
  'Malawi',
  'Mali',
  'Marokko',
  'Mauretanien',
  'Mauritius',
  'Mosambik',
  'Namibia',
  'Niger',
  'Nigeria',
  'Ruanda',
  'Sambia',
  'São Tomé und Príncipe',
  'Senegal',
  'Seychellen',
  'Sierra Leone',
  'Simbabwe',
  'Somalia',
  'Südafrika',
  'Sudan',
  'Südsudan',
  'Tansania',
  'Togo',
  'Tschad',
  'Tunesien',
  'Uganda',
  'Zentralafrikanische Republik',
];
export const ASIAN_COUNTRIES = [
  'Afghanistan',
  'Ägypten',
  'Armenien',
  'Aserbaidschan',
  'Bahrain',
  'Bangladesch',
  'Bhutan',
  'Brunei',
  'Volksrepublik China',
  'Georgien',
  'Indien',
  'Indonesien',
  'Irak',
  'Iran',
  'Israel',
  'Japan',
  'Jemen',
  'Jordanien',
  'Kambodscha',
  'Kasachstan',
  'Katar',
  'Kirgisistan',
  'Nordkorea',
  'Südkorea',
  'Kuwait',
  'Laos',
  'Libanon',
  'Malaysia',
  'Malediven',
  'Mongolei',
  'Myanmar',
  'Nepal',
  'Oman',
  'Pakistan',
  'Philippinen',
  'Russland',
  'Saudi-Arabien',
  'Singapur',
  'Sri Lanka',
  'Syrien',
  'Tadschikistan',
  'Thailand',
  'Türkei',
  'Turkmenistan',
  'Usbekistan',
  'Vereinigte Arabische Emirate',
  'Vietnam',
  'Zypern',
];
export const AUSTRALIA_AND_OCEANIA_COUNTRIES = [
  'Australien',
  'Cookinseln',
  'Fidschi',
  'Kiribati',
  'Marshallinseln',
  'Mikronesien',
  'Nauru',
  'Neuseeland',
  'Palau',
  'Salomonen',
  'Samoa',
  'Tonga',
  'Tuvalu',
  'Vanuatu',
  'Amerikanisch-Samoa',
  'Bougainville',
  'Französisch-Polynesien',
  'Guam',
  'Neukaledonien',
  'Niue',
  'Nördliche Marianen',
  'Norfolkinsel',
  'Pitcairninseln',
  'Tokelau',
  'Wallis und Futuna',
  'United States Minor Outlying Islands',
];
export const AMERICAN_COUNTRIES = [
  'Antigua und Barbuda',
  'Argentinien',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bolivien',
  'Brasilien',
  'Chile',
  'Costa Rica',
  'Dominica',
  'Dominikanische Republik',
  'El Salvador',
  'Ecuador',
  'Grenada',
  'Guatemala',
  'Guyana',
  'Haiti',
  'Honduras',
  'Jamaika',
  'Kanada',
  'Kolumbien',
  'Kuba',
  'Mexiko',
  'Nicaragua',
  'Panama',
  'Paraguay',
  'Peru',
  'St. Kitts und Nevis',
  'St. Lucia',
  'St. Vincent und die Grenadinen',
  'Suriname',
  'Trinidad und Tobago',
  'Uruguay',
  'Venezuela',
  'USA',
];

export const WORLD_COUNTRIES = {
  dach: DACH_COUNTRIES,
  europa: EUROPA_COUNTRIES,
  eu: EU_COUNTRIES,
  benelux: BENELUX_COUNTRIES,
  brics: BRICS_COUNTRIES,
  afrika: AFRICA_COUNTRIES,
  asien: ASIAN_COUNTRIES,
  'australien und ozeanien': AUSTRALIA_AND_OCEANIA_COUNTRIES,
  amerikas: AMERICAN_COUNTRIES,
};

export const MODEL_FIELDS = {
  zeitraum: [],
  region: ['dach'],
  boersennotiert: false,
  schwerpunkte: [],
  standort: WORLD_COUNTRIES['dach'],
};

export const INTEREST_OPTIONS = [
  { text: 'Tagesgeld', value: 'tagesgeld' },
  { text: 'Festgeld', value: 'festgeld' },
  { text: 'Sparbuch', value: 'sparbuch' },
  { text: 'Hypothekenzinsen', value: 'hypothekenzinsen' },
  { text: 'Ratenkredit 10 T €', value: 'ratenkredit' },
  { text: 'Giro', value: 'giro' },
  { text: 'Euribor', value: 'euribor' },
  { text: 'EONIA / ESTER', value: 'eonia' },
  { text: 'EZB', value: 'ezb_emp' },
  { text: 'Inflation', value: 'inflation' },
  { text: 'Umlaufrendite', value: 'umlaufrendite' },
  { text: 'Bundesanleihe 10 Jahre', value: 'ba_10' },
  { text: 'Pfandbriefe', value: 'pfandbriefe' },
  { text: 'Basiszins §247 BGB', value: 'basiszins_bgb' },
];

export const SUB_INTEREST_OPTIONS = {
  pfandbriefe: [
    { text: '1 Jahr', value: 'pb_1' },
    { text: '5 Jahre', value: 'pb_5' },
    { text: '10 Jahre', value: 'pb_10' },
  ],
  tagesgeld: [
    { text: 'Basiszins', value: 'tg_basis' },
    { text: '5.000 €', value: 'tg_5000' },
    { text: '50.000 €', value: 'tg_50000' },
    { text: 'laut Bundesbank', value: 'tg_bb' },
  ],
  festgeld: [
    { text: '3 Monate', value: 'fg_5000_3' },
    { text: '6 Monate', value: 'fg_5000_6' },
    { text: '12 Monate', value: 'fg_5000_12' },
    { text: '2 Jahre', value: 'sb_2' },
    { text: '3 Jahre', value: 'sb_3' },
    { text: '4 Jahre', value: 'sb_4' },
    { text: '5 Jahre', value: 'sb_5' },
    { text: '7 Jahre', value: 'sb_7' },
    { text: '10 Jahre', value: 'sb_10' },
  ],
  sparbuch: [
    { text: 'Basis- / Spareckzins', value: 'spareckzins' },
    { text: '50.000 €', value: 'spareinlage_50000' },
  ],
  hypothekenzinsen: [
    { text: '5 Jahre', value: 'bg_5' },
    { text: '10 Jahre', value: 'bg_10' },
    { text: '15 Jahre', value: 'bg_15' },
    { text: '20 Jahre', value: 'bg_20' },
    { text: 'Kfw 124', value: 'kfw_124' },
  ],
  ratenkredit: [
    { text: '36 Monate', value: 'rk_36' },
    { text: '48 Monate', value: 'rk_48' },
    { text: '60 Monate', value: 'rk_60' },
    { text: '72 Monate', value: 'rk_72' },
  ],
  giro: [
    { text: 'Dispo', value: 'dispo' },
    { text: 'Weitere Überziehung', value: 'dispo_plus' },
  ],
  euribor: [
    { text: '1 Monate', value: 'euribor_1' },
    { text: '3 Monate', value: 'euribor_3' },
    { text: '6 Monate', value: 'euribor_6' },
  ],
  ezb_emp: [
    { text: 'ReFi-Satz', value: 'ezb' },
    { text: 'Einlagenzins', value: 'ezb_einlagenzins' },
  ],
};

export const SPECIAL_CHART_FORM_OPTIONS = [
  { text: 'Hypothekenzinsen ab 1980', value: 'rbGrafikAuswahl1' },
  { text: 'Bausparzinsen ab 1993', value: 'rbGrafikAuswahl2' },
  { text: 'Forward-Aufschläge ab 2010', value: 'rbGrafikAuswahl3' },
  { text: 'Goldpreis laut Bundesbank', value: 'goldpreis' },
  { text: 'Hauspreis-Entwicklung ab 2004', value: 'rbGrafikAuswahl5' },
];

export const SPECIAL_CHART_FORM_SUB_OPTIONS = {
  rbGrafikAuswahl1: [
    { text: 'Zinsbindung 5 Jahre', value: 'effektivzins_5' },
    { text: 'Zinsbindung 10 Jahre', value: 'effektivzins_10' },
    { text: 'Zinsbindung 15 Jahre', value: 'effektivzins_15' },
  ],
  rbGrafikAuswahl2: [
    { text: 'BSV-Guthabenzins', value: 'bs_guthabenszins' },
    { text: 'Tagesgeldzinsen', value: 'bs_tagesgeld_index' },
    { text: 'BSV-Darlehenzins', value: 'bs_darlehenszins' },
    { text: 'Hypothekenzins 10 Jahre', value: 'bs_baugeld_10' },
  ],
  rbGrafikAuswahl3: [
    { text: 'Aufschlag für 12 Monate', value: 'fd_aufschlag_12' },
    { text: 'Aufschlag für 24 Monate', value: 'fd_aufschlag_24' },
    { text: 'Aufschlag für 36 Monate', value: 'fd_aufschlag_36' },
    {
      text: 'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 12 Mon.',
      value: 'fd_effektivzins_12',
    },
    {
      text: 'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 24 Mon.',
      value: 'fd_effektivzins_24',
    },
    {
      text: 'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 36 Mon.',
      value: 'fd_effektivzins_36',
    },
  ],
  rbGrafikAuswahl5: [
    {
      text: 'Bauzinsen und Kredithöhe',
      value: 'hp_darlehenhoehe',
      additionalValue: {
        text: 'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        value: 'hp_hypothekenzins_index',
      },
    },
    {
      text: 'Nettoeinkommen und Kredithöhe',
      value: 'hp_einkommen',
      additionalValue: {
        text: 'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        value: 'hp_darlehenhoehe',
        translateKey: 'hp_einkommen_hp_darlehenhoehe',
      },
    },
    {
      text: 'Kredithöhe und Immobilienpreise',
      value: 'hp_preis_neubau_etw',
      additionalValue: {
        text: 'Mögliche Kredithöhe in Euro',
        value: 'hp_darlehenhoehe',
        translateKey: 'hp_preis_neubau_etw_hp_darlehenhoehe',
      },
      additionalValue2: {
        text: 'Durchschnittliche Neubau-Hauspreise in Euro',
        value: 'hp_preis_neubau_haus',
        translateKey: 'hp_preis_neubau_etw_hp_preis_neubau_haus',
      },
    },
    {
      text: 'Hauspreis und Nettoeinkommen',
      value: 'hp_preis_neubau_haus',
      additionalValue: {
        text: 'Nettoeinkommen p. M. für 2 Personen',
        value: 'hp_einkommen',
        translateKey: 'hp_preis_neubau_haus_hp_einkommen',
      },
    },
  ],
};

export const ALL_COUNTRIES = [
  DACH_COUNTRIES,
  EUROPA_COUNTRIES,
  EU_COUNTRIES,
  BENELUX_COUNTRIES,
  BRICS_COUNTRIES,
  AFRICA_COUNTRIES,
  ASIAN_COUNTRIES,
  AUSTRALIA_AND_OCEANIA_COUNTRIES,
  AMERICAN_COUNTRIES,
].flat();
export const MONTHS = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12',
};
export const WETTBEWERB_CHART_OPTIONS = {
  'wettbewerb-baugeld': {
    minWidth: 2.5,
    maxWidth: 1.5,
    minColor: '#EBC100',
    maxColor: '#181818',
  },
  'wettbewerb-festgeld': {
    minWidth: 1.5,
    maxWidth: 2.5,
    minColor: '#cc0086',
    maxColor: '#ff3339',
  },
  'wettbewerb-tagesgeld': {
    minWidth: 1.5,
    maxWidth: 2.5,
    minColor: '#cc0086',
    maxColor: '#ff3339',
  },
};
export const TAGESGELD_TABLE_HEADERS_ORDER = {
  anbieter: 0,
  produkt: 1,
  produkt_aktionszinssatz: 2,
  produkt_abBetrag: 3,
  produkt_zinssatz: 4,
  produkt_zinsgarantie: 5,
  produkt_anschlussZinssatz: 6,
  produkt_datumZinsaenderung: 7,
  produkt_neukundenangebot: 8,
  produkt_mindestanlage: 9,
  produkt_maximalanlage: 10,
  produkt_maximalanlageProdukt: 11,
  einlagensicherung: 12,
  produkt_zinssatzVeraenderung: 13,
  produkt_zinssatz_difference: 14,
  produkt_aktionszinssatzVeraenderung: 15,

  produkt_zinsgutschrift: 16,
  produkt_zinsberechnung: 17,
};
export const FESTGELD_TABLE_HEADERS_ORDER = {
  anbieter: 0,
  produkt: 1,
  '1M_zinssatz': 2,
  '1M_zinssatzVeraenderung': 3,
  '1M_zinssatz_difference': 4,
  '2M_zinssatz': 5,
  '2M_zinssatzVeraenderung': 6,
  '2M_zinssatz_difference': 7,
  '3M_zinssatz': 8,
  '3M_zinssatzVeraenderung': 9,
  '3M_zinssatz_difference': 10,
  '6M_zinssatz': 11,
  '6M_zinssatzVeraenderung': 12,
  '6M_zinssatz_difference': 13,
  '9M_zinssatz': 14,
  '9M_zinssatzVeraenderung': 15,
  '9M_zinssatz_difference': 16,
  '12M_zinssatz': 17,
  '12M_zinssatzVeraenderung': 18,
  '12M_zinssatz_difference': 19,
  '18M_zinssatz': 20,
  '18M_zinssatzVeraenderung': 21,
  '18M_zinssatz_difference': 22,
  '24M_zinssatz': 23,
  '24M_zinssatzVeraenderung': 24,
  '24M_zinssatz_difference': 25,
  '36M_zinssatz': 26,
  '36M_zinssatzVeraenderung': 27,
  '36M_zinssatz_difference': 28,
  '48M_zinssatz': 29,
  '48M_zinssatzVeraenderung': 30,
  '48M_zinssatz_difference': 31,
  '60M_zinssatz': 32,
  '60M_zinssatzVeraenderung': 33,
  '60M_zinssatz_difference': 34,
  '72M_zinssatz': 35,
  '72M_zinssatzVeraenderung': 36,
  '72M_zinssatz_difference': 37,
  '84M_zinssatz': 38,
  '84M_zinssatzVeraenderung': 39,
  '84M_zinssatz_difference': 40,
  '96M_zinssatz': 41,
  '96M_zinssatzVeraenderung': 42,
  '96M_zinssatz_difference': 43,
  '108M_zinssatz': 44,
  '108M_zinssatzVeraenderung': 45,
  '108M_zinssatz_difference': 46,
  '120M_zinssatz': 47,
  '120M_zinssatzVeraenderung': 48,
  '120M_zinssatz_difference': 49,
  produkt_datumZinsaenderung: 50,
  produkt_mindestanlage: 51,
  produkt_maximalanlage: 52,
  produkt_zinsgutschrift: 53,
  produkt_zinsberechnung: 54,
  einlagensicherung: 55,
};

export const DEFAULT_REPORT_OVERVIEW_CONTENT = {
  title: '',
  uid: '',
  slug: '',
  tags: [],
  note: '',
  headers: [],
  rows: [],
  is_preconfigured: false,
  meta: {
    availableData: [],
  },
  report_type: 0,
};

export const DEFAULT_LIVE_DATA_REPORT_CONFIG = {
  next: null,
  previous: null,
  count: 0,
  page: 1,
  itemsPerPage: 8,
  results: [],
};

export const MARKER_IO_IDS = {
  de: '647deb73638da378391052fd',
  en: '65aa566afbc498fce7cfa9fd',
};

export const BACKEND_URL =
  getEnv('VUE_APP_BACKEND_URL') || 'https://staging-bp.fmh.de';
export const AUTH_API =
  getEnv('VUE_APP_AUTH_API') || 'https://staging-bp.fmh.de';
export const MATOMO_SITE_ID = getEnv('VUE_APP_MATOMO_SITE_ID') || '';
export const MATOMO_HOST = getEnv('VUE_APP_MATOMO_HOST') || '';
export const SUPERSET_BACKEND_URL =
  getEnv('VUE_APP_SUPERSET_BACKAND_URL') || 'https://superset.fmh.de';
export const HOST = getEnv('VUE_APP_HOST') || 'https://dev.bankenportal.fmh.de';
export const IS_TRANSLATE_TO_EN = getEnv('VUE_APP_TRANSLATION_EN');
export const IS_NEW_TAGESGELD_ENABLED =
  String(getEnv('VUE_APP_NEW_TAGESGELD_ENABLED') || 'false') === 'true';
export const IS_CREATE_PAGE_AVAILABLE =
  String(getEnv('VUE_APP_CREATE_CONFIG_PAGE') || 'false') === 'true';
