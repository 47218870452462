import {
  DEFAULT_LIVE_DATA_REPORT_CONFIG,
  INTEREST_CREDIT_TAGESGELD,
  IS_NEW_TAGESGELD_ENABLED,
  KEYS_TAGS_BAUGELD,
} from '@/utils/constants';
import {
  IFestgeldInterestConfig,
  ITagesgeldInterestConfig,
  LiveDataReportConfig,
  ReportConfigApiParams,
} from '@/interfaces';
import { instance } from '@/utils';
import { modifyAllReports } from '@/utils/helpers/index';

export const mappedInterestCredit = (
  value: 0 | 1 | 2 | 3 | 4 | 5,
  localeObject:
    | ITagesgeldInterestConfig
    | IFestgeldInterestConfig = INTEREST_CREDIT_TAGESGELD
) => localeObject[value] || value;

const fetchConfig = async (
  signal: AbortSignal,
  params: ReportConfigApiParams,
  category: string,
  reportType: number
) => {
  const { data } = await instance.get('/api/reports-config/', {
    signal,
    params,
  });
  return {
    next: data.next,
    previous: data.previous,
    count: data.count,
    results: modifyAllReports(data?.results, reportType, category),
  };
};
export const fetchConfigs = async (
  reportType: number,
  commit: (path: string, val: AbortController[]) => void
) => {
  const types = IS_NEW_TAGESGELD_ENABLED ? [8, 1] : [1];
  const controller1 = new AbortController();
  const controller2 = new AbortController();
  const controller3 = new AbortController();
  const signal1 = controller1.signal;
  const signal2 = controller2.signal;
  const signal3 = controller3.signal;

  let latest: LiveDataReportConfig = { ...DEFAULT_LIVE_DATA_REPORT_CONFIG };
  let autoUpdate: LiveDataReportConfig = {
    ...DEFAULT_LIVE_DATA_REPORT_CONFIG,
  };
  let other: LiveDataReportConfig = { ...DEFAULT_LIVE_DATA_REPORT_CONFIG };

  if (types.includes(reportType)) {
    const controllerA = new AbortController();
    const controllerB = new AbortController();
    const controllerC = new AbortController();
    const signalA = controllerA.signal;
    const signalB = controllerB.signal;
    const signalC = controllerC.signal;
    commit('addControllers', [controllerA, controllerB, controllerC]);
    const params: ReportConfigApiParams = {
      is_temporary: false,
      ordering: '-timestamp',
    };
    types.length === 1
      ? (params.report_type = 1)
      : (params.report_type__in = '1,8');
    const respLatest = await fetchConfig(signalA, params, 'latest', reportType);
    if (respLatest?.results?.length) {
      latest = { ...respLatest, page: 1, itemsPerPage: 3 };
    }
    const paramsAutoUpdate: ReportConfigApiParams = {
      selection_type: 'selection_autoupdate',
      is_temporary: false,
      ordering: '-timestamp',
      page_size: 8,
    };
    types.length === 1
      ? (paramsAutoUpdate.report_type = 1)
      : (paramsAutoUpdate.report_type__in = '1,8');
    const respAutoUpdate = await fetchConfig(
      signalB,
      paramsAutoUpdate,
      'autoUpdate',
      reportType
    );

    if (respAutoUpdate?.results?.length) {
      autoUpdate = { ...respAutoUpdate, page: 1, itemsPerPage: 8 };
    }
    const paramsOther: ReportConfigApiParams = {
      selection_type: 'selection_longterm,selection_individualupdate',
      is_temporary: false,
      page_size: 8,
      ordering: '-timestamp',
    };
    types.length === 1
      ? (paramsOther.report_type = 1)
      : (paramsOther.report_type__in = '1,8');
    const respOtherReports = await fetchConfig(
      signalC,
      paramsOther,
      'other',
      reportType
    );
    if (respOtherReports?.results?.length) {
      other = { ...respOtherReports, page: 1, itemsPerPage: 8 };
    }
  } else {
    commit('addControllers', [controller1, controller2, controller3]);
    const respLatest = await fetchConfig(
      signal1,
      {
        report_type: reportType,
        is_temporary: false,
        ordering: '-timestamp',
        page_size: 8,
      },
      'latest',
      reportType
    );
    if (respLatest?.results?.length) {
      latest = { ...respLatest, page: 1, itemsPerPage: 3 };
    }
    const reportsAutoUpdate = await fetchConfig(
      signal2,
      {
        selection_type: 'selection_autoupdate',
        is_temporary: false,
        report_type: reportType,
        page_size: 8,
      },
      'autoUpdate',
      reportType
    );
    if (reportsAutoUpdate?.results?.length) {
      autoUpdate = { ...reportsAutoUpdate, page: 1, itemsPerPage: 8 };
    }
    const otherReports = await fetchConfig(
      signal3,
      {
        selection_type: 'selection_longterm,selection_individualupdate',
        is_temporary: false,
        report_type: reportType,
        page_size: 8,
      },
      'other',
      reportType
    );
    if (otherReports?.results?.length) {
      other = { ...otherReports, page: 1, itemsPerPage: 8 };
    }
  }
  return {
    latest: { ...latest, results: latest.results.slice(0, 3) },
    autoUpdate,
    other,
  };
};

export function sortByDate(
  items,
  sortBy: string,
  sortDesc: boolean[],
  isNestedArray: boolean = false
) {
  return items.sort((a, b) => {
    let partsA = '';
    let partsB = '';
    if (isNestedArray) {
      partsA = a[0]?.[sortBy] ? a[0]?.[sortBy].split('.') : '';
      partsB = b[0]?.[sortBy] ? b[0]?.[sortBy].split('.') : '';
    }
    if (!isNestedArray) {
      partsA = a[sortBy] ? a[sortBy].split('.') : '';
      partsB = b[sortBy] ? b[sortBy].split('.') : '';
    }
    let first =
      new Date(isNestedArray ? a[0]?.[sortBy] : a[sortBy]).getTime() || 0;
    let second =
      new Date(isNestedArray ? b[0]?.[sortBy] : b[sortBy]).getTime() || 0;
    //the valid date format for new Date is YYYY-MM-DD
    if (partsA.length === 3) {
      first = new Date(
        `${partsA[2]}-${partsA[1]}-${partsA[0]}T00:00:00`
      ).getTime();
    }
    if (partsB.length === 3) {
      second = new Date(
        `${partsB[2]}-${partsB[1]}-${partsB[0]}T00:00:00`
      ).getTime();
    }
    if (sortDesc.includes(true)) {
      if (second > first) {
        return 1;
      }
      if (second < first) {
        return -1;
      }
      return 0;
    }
    if (sortDesc.includes(false)) {
      if (first > second) {
        return 1;
      }
      if (first < second) {
        return -1;
      }
      return 0;
    }
  });
}
export function sortStrings(
  items: Record<string, string>[] | Array<Record<string, string>[]>,
  sortBy: string,
  sortDesc: boolean[],
  isNestedArray: boolean = false
) {
  return items.sort((a, b) => {
    const first = isNestedArray
      ? (a[0]?.[sortBy] || '').trim().toLowerCase()
      : (a[sortBy] || '').trim().toLowerCase();
    const second = isNestedArray
      ? (b[0]?.[sortBy] || '').trim().toLowerCase()
      : (b[sortBy] || '').trim().toLowerCase();
    return sortDesc.includes(true)
      ? first > second
        ? -1
        : 1
      : first > second
      ? 1
      : -1;
  });
}

const generate = (items, isNestedArray: boolean) => {
  const months: Record<string, string>[] | Array<Record<string, string>[]> = [];
  const dates: Record<string, string>[] | Array<Record<string, string>[]> = [];
  const rest: Record<string, string>[] | Array<Record<string, string>[]> = [];
  if (isNestedArray) {
    items.forEach((subItems: Record<string, string>[]) => {
      const subMonth: Record<string, string>[] = [];
      const subDates: Record<string, string>[] = [];
      const subRest: Record<string, string>[] = [];
      subItems.forEach((i) => {
        if (i.produkt_zinsgarantie) {
          const parts = i.produkt_zinsgarantie.split('.');
          if (parts.length !== 3) {
            subMonth.push(i);
          }
          if (parts.length === 3) {
            subDates.push(i);
          }
        }
        if (!i.produkt_zinsgarantie) {
          subRest.push({ ...i, produkt_zinsgarantie: '' });
        }
      });
      (months as Array<Record<string, string>[]>).push(subMonth);
      (dates as Array<Record<string, string>[]>).push(subDates);
      (rest as Array<Record<string, string>[]>).push(subRest);
    });
  } else {
    items.forEach((item) => {
      if (typeof item.produkt_zinsgarantie === 'string') {
        const parts = item.produkt_zinsgarantie.split('.');
        if (parts.length !== 3) {
          months.push(item);
        }
        if (parts.length === 3) {
          dates.push(item);
        }
      }
      if (!item.produkt_zinsgarantie) {
        rest.push({ ...item, produkt_zinsgarantie: '' });
      }
    });
  }
  return {
    months,
    dates,
    rest,
  };
};
export function sortInterestRate(
  items,
  sortDesc: boolean[],
  isNestedArray: boolean = false
) {
  const { months, dates, rest } = generate(items, isNestedArray);

  const sortedMonth = sortStrings(
    months,
    'produkt_zinsgarantie',
    sortDesc,
    isNestedArray
  );
  const sortedDates = sortByDate(
    dates,
    'produkt_zinsgarantie',
    sortDesc,
    isNestedArray
  );
  if (sortDesc.includes(true)) {
    return (sortedMonth as any).concat(sortedDates).concat(rest);
  }
  if (sortDesc.includes(false)) {
    return (rest as any).concat(sortedDates).concat(sortedMonth);
  }
}
export function sortCredit(items, sortBy: string, sortDesc: boolean[]) {
  const daily: object[] = [];
  const beginningMonth: object[] = [];
  const endOfMonth: object[] = [];
  const endOfQuarter: object[] = [];
  const endOfHalfYear: object[] = [];
  const yearEnd: object[] = [];
  const rest: object[] = [];
  items.forEach((item) => {
    if (!isNaN(+item[sortBy])) {
      const val = item[sortBy];
      //Monatsende
      if (+val === 0) {
        endOfMonth.push(item);
      }
      //Quartalsende
      if (+val === 1) {
        endOfQuarter.push(item);
      }
      //Jahresende
      if (val === 2) {
        yearEnd.push(item);
      }
      //Täglich
      if (+val === 3) {
        daily.push(item);
      }
      //Halbjahresende
      if (+val === 4) {
        endOfHalfYear.push(item);
      }
      //Monatsanfang
      if (+val === 5) {
        beginningMonth.push(item);
      }
    }
    if (isNaN(+item[sortBy])) {
      rest.push(item);
    }
  });
  if (sortDesc.includes(true)) {
    return daily
      .concat(beginningMonth)
      .concat(endOfMonth)
      .concat(endOfQuarter)
      .concat(endOfHalfYear)
      .concat(yearEnd)
      .concat(rest);
  }
  if (sortDesc.includes(false)) {
    return rest
      .concat(yearEnd)
      .concat(endOfHalfYear)
      .concat(endOfQuarter)
      .concat(endOfMonth)
      .concat(beginningMonth)
      .concat(daily);
  }
}
export function sortNumbers(
  items,
  sortBy: string,
  sortDesc: boolean[],
  isNestedArray: boolean = false
) {
  return items.sort((a, b) => {
    const first = isNestedArray ? +a[0][sortBy] || 0 : +a[sortBy] || 0;
    const second = isNestedArray ? +b[0][sortBy] || 0 : +b[sortBy] || 0;
    return sortDesc.includes(true) ? second - first : first - second;
  });
}

export function updateTags(tags: string[], reportType: number) {
  return tags
    .map((tag) => {
      if (tag.includes('deutsche') || tag.includes('erweiterte')) {
        return `${tag?.charAt(0).toUpperCase()}${tag?.slice(1)}`;
      }
      return tag;
    })
    .filter((tag) => {
      if (reportType === 3) {
        return KEYS_TAGS_BAUGELD.includes(tag);
      }
      return true;
    });
}
